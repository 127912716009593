<template>
    <div class="main">
        <div class="container py-70">
            <a-row  type="flex" justify="center">
                <a-col>
                    <div class="page-header pb-70">
                            <h1 class="text-primary">Features</h1>
                    </div>
                    <h3 class="has-text-align-center wp-block-heading">Timbeter is a smart-device solution for accurate timber log measurement and data management. Measure your logs in a pile or on a truck using these great timber tools below.</h3>
                    <div style="height:30px" aria-hidden="true" class="wp-block-spacer"></div>
                    <h3 class="has-text-align-center wp-block-heading">BASIC FEATURES</h3>
                    <div class="wp-block-columns is-layout-flex wp-container-5">
                            <div class="wp-block-column is-layout-flow">
                            <div id="diameter-measurement" class="wp-block-image"><figure class="aligncenter size-full"><img decoding="async" width="1063" height="709" src="https://timbeter.com/wp-content/uploads/2022/04/diameter.png" alt="" class="wp-image-22794" srcset="https://timbeter.com/wp-content/uploads/2022/04/diameter.png 1063w, https://timbeter.com/wp-content/uploads/2022/04/diameter-768x512.png 768w, https://timbeter.com/wp-content/uploads/2022/04/diameter-420x280.png 420w, https://timbeter.com/wp-content/uploads/2022/04/diameter-720x480.png 720w" sizes="(max-width: 1063px) 100vw, 1063px"><figcaption><a href="https://timbeter.com/feature-overview/#diameter-measurement">DIAMETER MEASUREMENT</a></figcaption></figure></div>
                            </div>



                            <div class="wp-block-column is-layout-flow">
                            <div class="wp-block-image"><figure class="aligncenter size-full"><img decoding="async" loading="lazy" width="1063" height="709" src="https://timbeter.com/wp-content/uploads/2022/04/contour.png" alt="" class="wp-image-22761" srcset="https://timbeter.com/wp-content/uploads/2022/04/contour.png 1063w, https://timbeter.com/wp-content/uploads/2022/04/contour-768x512.png 768w, https://timbeter.com/wp-content/uploads/2022/04/contour-420x280.png 420w, https://timbeter.com/wp-content/uploads/2022/04/contour-720x480.png 720w" sizes="(max-width: 1063px) 100vw, 1063px"><figcaption><a href="https://timbeter.com/feature-overview/#contour-measurement">CONTOUR MEASUREMENT</a></figcaption></figure></div>
                            </div>



                            <div class="wp-block-column is-layout-flow">
                            <div class="wp-block-image"><figure class="aligncenter size-full"><img decoding="async" loading="lazy" width="1063" height="709" src="https://timbeter.com/wp-content/uploads/2022/04/truck.png" alt="" class="wp-image-22827" srcset="https://timbeter.com/wp-content/uploads/2022/04/truck.png 1063w, https://timbeter.com/wp-content/uploads/2022/04/truck-768x512.png 768w, https://timbeter.com/wp-content/uploads/2022/04/truck-420x280.png 420w, https://timbeter.com/wp-content/uploads/2022/04/truck-720x480.png 720w" sizes="(max-width: 1063px) 100vw, 1063px"><figcaption><a href="https://timbeter.com/feature-overview/#truck-measurement">TRUCK MEASUREMENT</a></figcaption></figure></div>
                            </div>



                            <div class="wp-block-column is-layout-flow">
                            <div class="wp-block-image"><figure class="aligncenter size-full"><img decoding="async" loading="lazy" width="1063" height="709" src="https://timbeter.com/wp-content/uploads/2022/04/density.png" alt="" class="wp-image-22783" srcset="https://timbeter.com/wp-content/uploads/2022/04/density.png 1063w, https://timbeter.com/wp-content/uploads/2022/04/density-768x512.png 768w, https://timbeter.com/wp-content/uploads/2022/04/density-420x280.png 420w, https://timbeter.com/wp-content/uploads/2022/04/density-720x480.png 720w" sizes="(max-width: 1063px) 100vw, 1063px"><figcaption><a href="https://timbeter.com/feature-overview/#density-measurement">DENSITY MEASUREMENT</a></figcaption></figure></div>
                            </div>
                    </div>
                    <div class="wp-block-columns is-layout-flex wp-container-10">
                        <div class="wp-block-column is-layout-flow">
                        <div class="wp-block-image"><figure class="aligncenter size-full"><img decoding="async" loading="lazy" width="1063" height="709" src="https://timbeter.com/wp-content/uploads/2022/04/storage-module-3.png" alt="" class="wp-image-23324" srcset="https://timbeter.com/wp-content/uploads/2022/04/storage-module-3.png 1063w, https://timbeter.com/wp-content/uploads/2022/04/storage-module-3-768x512.png 768w, https://timbeter.com/wp-content/uploads/2022/04/storage-module-3-420x280.png 420w, https://timbeter.com/wp-content/uploads/2022/04/storage-module-3-720x480.png 720w" sizes="(max-width: 1063px) 100vw, 1063px"><figcaption><a href="https://timbeter.com/feature-overview/#storage-module">STORAGE MODULE</a></figcaption></figure></div>
                        </div>



                        <div class="wp-block-column is-layout-flow">
                        <div class="wp-block-image"><figure class="aligncenter size-full"><img decoding="async" loading="lazy" width="1063" height="709" src="https://timbeter.com/wp-content/uploads/2022/04/deffect-marking.png" alt="" class="wp-image-22772" srcset="https://timbeter.com/wp-content/uploads/2022/04/deffect-marking.png 1063w, https://timbeter.com/wp-content/uploads/2022/04/deffect-marking-768x512.png 768w, https://timbeter.com/wp-content/uploads/2022/04/deffect-marking-420x280.png 420w, https://timbeter.com/wp-content/uploads/2022/04/deffect-marking-720x480.png 720w" sizes="(max-width: 1063px) 100vw, 1063px"><figcaption><a href="https://timbeter.com/feature-overview/#defect-marking">DEFECT MARKING</a></figcaption></figure></div>
                        </div>



                        <div class="wp-block-column is-layout-flow">
                        <div class="wp-block-image"><figure class="aligncenter size-full"><img decoding="async" loading="lazy" width="1063" height="709" src="https://timbeter.com/wp-content/uploads/2022/04/panorama-3.png" alt="" class="wp-image-23313" srcset="https://timbeter.com/wp-content/uploads/2022/04/panorama-3.png 1063w, https://timbeter.com/wp-content/uploads/2022/04/panorama-3-768x512.png 768w, https://timbeter.com/wp-content/uploads/2022/04/panorama-3-420x280.png 420w, https://timbeter.com/wp-content/uploads/2022/04/panorama-3-720x480.png 720w" sizes="(max-width: 1063px) 100vw, 1063px"><figcaption><a href="https://timbeter.com/feature-overview/#panorama">PANORAMA</a></figcaption></figure></div>
                        </div>



                        <div class="wp-block-column is-layout-flow">
                        <div class="wp-block-image"><figure class="aligncenter size-full"><img decoding="async" loading="lazy" width="1063" height="709" src="https://timbeter.com/wp-content/uploads/2022/04/calibration.png" alt="" class="wp-image-22750" srcset="https://timbeter.com/wp-content/uploads/2022/04/calibration.png 1063w, https://timbeter.com/wp-content/uploads/2022/04/calibration-768x512.png 768w, https://timbeter.com/wp-content/uploads/2022/04/calibration-420x280.png 420w, https://timbeter.com/wp-content/uploads/2022/04/calibration-720x480.png 720w" sizes="(max-width: 1063px) 100vw, 1063px"><figcaption><a href="https://timbeter.com/feature-overview/#calibration-tool">CALIBRATION TOOL</a></figcaption></figure></div>
                        </div>
                    </div>
                </a-col>
            </a-row>
        </div>
    </div>
</template>
<style scoped>

.main{
    width:100%;
    background-color: #e0e0e0;
}
.ant-col{
    padding-left: 15px;
    padding-right: 15px;
}
.container{
    width: 1140px;
    margin: auto;
}
.pb-70, .py-70 {
    padding-bottom: 70px !important;
    padding-top: 70px !important;
}
.text-primary {
    color: #3a9144 !important;
}
.page-header{
    text-align: left;
}
h1, .h1 {
    font-size: 2.5rem;
    margin-bottom: 0.5rem;
    font-weight: 500;
    line-height: 1.
}
.has-text-align-center {
    text-align: center;
}

h3 {
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    font-size: 2rem;
    line-height: 2.5rem;
    margin-bottom: 2rem;
    color: #6d6e71;
}
.wp-block-columns{
    align-items: normal!important;
    box-sizing: border-box;
    display: flex;
    margin-right: 10px;
    margin-bottom: 1.75em;
    flex-wrap: nowrap;
}
.wp-block-column {
    flex-grow: 1;
    min-width: 0;
    margin-left: 10px;
    overflow-wrap: break-word;
    word-break: break-word;
}
.is-layout-flow{
    display: block;
}
.wp-block-image {
    margin: 2rem 0;
}
.wp-block-image .aligncenter {
    margin-left: auto;
    margin-right: auto;
}
.wp-block-image img {
    max-width: 100%;
    height: auto;
}
</style>